.App-header {
  background-color: #050505;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #f3f3f3;
  font-family: Roboto, sans-serif;;
}

.hyper-link {
  color: #f3f3f3;
  font-family: roboto;
  text-decoration: underline;
}

.button {
  background-color:#252120;
  border: #252120;
  padding: 10px 24px;
  border-radius: 4px;
  font-family: roboto;
  color: #f3f3f3;
  transition-duration: 0.4s;
}

.button:hover {
  background-color:#958969
}

.header {
  background-image:
  radial-gradient(rgba(255, 255, 255, 0), rgba(26, 16, 17, 0.24)),
  url('../images/banner-pic.jpg');
  background-size:cover;
  background-position: 55% 15%;
}


.invert:hover {
  filter: brightness(0.5)
}

body {
  background-color: #050505;
}

a, p, li, h1, label {
  color: #f3f3f3;
  font-family: roboto;
} 

a:hover {
  color: #958969
}

.carousel-responsive {
  height: 700px;
}

@media (max-width: 991.98px) { 
  .carousel-responsive {
    height: 400px;
  }
}




